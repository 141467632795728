import React from "react";
import "./index.css";

export const QuoteContainer = ({ quoteText, loading }) => {
  return (
    <div className="quoteBox">
      <div className="quoteText">
        {loading ? (
          <span>Loading...</span>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: quoteText }} />
        )}
      </div>
    </div>
  );
};
