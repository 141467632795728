/*
TODO

add base of movies to guess from
make numbers clickable to show that quote
reveal all quotes when correct guess
set puzzle number
make base of movie puzzles

add ? and $ modals
*/
import React, { useState, useEffect } from 'react';
import { QuoteContainer } from './quoteContainer';
import { QuoteNumbers } from './quoteNumbers';
import { PrevGuesses } from './prevGuesses';
import { CongratsModal } from './congratsModal';
import './index.css';

function getFormattedDate() {
  const today = new Date();

  // Get day, month, and year
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  // Concatenate the date parts
  const formattedDate = day + month + year;

  return formattedDate;
}

const API_URL = 'https://0sp91xepr0.execute-api.us-east-2.amazonaws.com/dev';

const App = () => {
  const [currentQuote, setCurrentQuote] = useState(0);
  const [prevGuesses, setPrevGuesses] = useState([]);
  const [guess, setGuess] = useState('');
  const [showCongratsModal, setShowCongratsModal] = useState(false); // State to control modal visibility
  const [correctGuess, setCorrectGuess] = useState(false);
  const [movieSuggestions, setMovieSuggestions] = useState([]);
  const [quotes, setQuotes] = useState(Array(6).fill(''));
  const [loading, setLoading] = useState(false);
  const [gameResult, setGameResult] = useState(null); // Possible values: "win", "lose", or null
  const [selectedNumber, setSelectedNumber] = useState(currentQuote);
  const [selectedQuote, setSelectedQuote] = useState(quotes[currentQuote]);
  const [message, setMessage] = useState(''); // Add this line

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
    setSelectedQuote(quotes[number - 1]);
  };

  useEffect(() => {
    const fetchMovieSuggestions = async () => {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ guess }),
          redirect: 'follow',
        };
        const response = await fetch('https://3wc6ft0pqf.execute-api.us-east-2.amazonaws.com/dev', requestOptions);
        if (!response.ok) {
          throw new Error('Failed to fetch movie suggestions');
        }

        const data = await response.json();
        const suggestions = data.body || [];
        setMovieSuggestions(suggestions);
      } catch (error) {
        console.error('Error fetching movie suggestions:', error);
      }
    };

    fetchMovieSuggestions();
  }, [guess]); // Fetch movie suggestions when guess changes

  useEffect(() => {
    const fetchInitialQuote = async () => {
      const initialQuotes = [];
      initialQuotes.push(await getNextQuote(0, initialQuotes, setQuotes));
      setQuotes(initialQuotes);
    };

    fetchInitialQuote();
  }, []); // Fetch initial quote only once when component mounts

  const handleInputChange = (event) => {
    setGuess(event.target.value);
  };

  const callAPI = () => {
    const formattedDate = getFormattedDate();  // Add this line to get the formatted date
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ guess, date: formattedDate }),  // Pass the date to the Lambda function
      redirect: 'follow',
    };

    fetch('https://evp2bjmxr7.execute-api.us-east-2.amazonaws.com/dev', requestOptions)
      .then((response) => response.json())
      .then((result) => handleResult(result))
      .catch((error) => console.log('error', error));
  };

  const handleResult = async (result) => {
    const updatedGuesses = [...prevGuesses];
    
    // Parse the response body
    const responseBody = JSON.parse(result.body);
    
    console.log(responseBody); // Log the parsed body to check its structure

    if (responseBody.message === "Correct") {
        updatedGuesses.push(["🟩", guess]);
        setCorrectGuess(true);
        setShowCongratsModal(true); // Show the modal for correct answers
        setGameResult("win");
        setSelectedNumber(null);

        const newQuotes = [...quotes];
        if (responseBody.quotes) {
            responseBody.quotes.forEach((quote, index) => {
                newQuotes[index] = quote;
            });
        }
        setQuotes(newQuotes);

        setMessage(`${responseBody.movie_title}`);
    } else {
        console.log('try again!');
        setGuess('');
        updatedGuesses.push(["🟥", guess]);
        setCorrectGuess(false);
        if (updatedGuesses.length === 6) {
            // Show a different modal after 6 incorrect guesses
            setShowCongratsModal(true);
            setGameResult("lose");
        }
    }

    setPrevGuesses(updatedGuesses);

    if (currentQuote < 5) {
        const nextQuoteIndex = currentQuote + 1;
        setCurrentQuote(nextQuoteIndex);

        const newQuote = await getNextQuote(nextQuoteIndex, quotes, setQuotes);
        const newQuotes = [...quotes];
        newQuotes[nextQuoteIndex] = newQuote;
        setQuotes(newQuotes);

        // Update selected quote after setting new quotes
        setSelectedNumber(nextQuoteIndex + 1);
        setSelectedQuote(newQuotes[nextQuoteIndex]);
    }
};


  const closeModal = () => {
    setShowCongratsModal(false);
    setGuess('');
  };

  const getNextQuote = async (quoteIndex, quotesArray, setQuotes) => {
    try {
      setLoading(true);
      const formattedDate = getFormattedDate();
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ quote_index: quoteIndex, date: formattedDate }),
        redirect: 'follow',
      };
      const response = await fetch(API_URL, requestOptions);
      if (!response.ok) {
        throw new Error('Failed to fetch next quote');
      }

      const data = await response.json();
      const nextQuote = data.body || '';

      // Update the local quotes array
      const updatedQuotes = [...quotesArray];
      updatedQuotes[quoteIndex] = nextQuote;
      setQuotes(updatedQuotes);

      return nextQuote;
    } catch (error) {
      console.error('Error fetching next quote:', error);
      return '';
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <h1 className="title">Screenplayed</h1>
      <QuoteContainer quoteText={selectedQuote || quotes[currentQuote]} loading={loading} />
      <QuoteNumbers
        activeNumber={currentQuote + 1}
        selectedNumber={selectedNumber}
        onNumberClick={handleNumberClick}
        gameResult={gameResult}
      />
      <div className={`input-container ${gameResult ? "hidden" : ""}`} name="guessMovie">
        {gameResult ? (
          <div className="correct-answer">The correct answer is... {message}</div>
        ) : (
          <>
            <input
              type="text"
              list="movies"
              id="guess"
              className="input-field"
              placeholder="Type here..."
              name="movieTitle"
              value={guess}
              onChange={handleInputChange}
            />
            <datalist id="movies" className="custom-datalist">
              {movieSuggestions.map((movie, index) => (
                <option key={index} value={movie} />
              ))}
            </datalist>
            <button type="button" className="submit-button" onClick={callAPI}>
              Submit
            </button>
          </>
        )}
      </div>
      <PrevGuesses guesses={prevGuesses} />
      {showCongratsModal && <CongratsModal closeModal={closeModal} prevGuesses={prevGuesses} correctGuess={correctGuess} />}
    </div>
  );
};

export default App;
