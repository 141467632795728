import React, { useState } from "react";
import "./index.css";
import { QuoteNumber } from "./quoteNumber";

export const QuoteNumbers = ({ activeNumber, selectedNumber, onNumberClick, gameResult }) => {
  return (
    <div className="quoteNumbersContainer">
      {[1, 2, 3, 4, 5, 6].map((number) => (
        <QuoteNumber
          key={number}
          number={number}
          isActive={number === selectedNumber}
          isVisible={number <= activeNumber || gameResult === "win"}
          onClick={onNumberClick}
        />
      ))}
    </div>
  );
};