import React from "react";
import "./index.css";

export const PrevGuesses = ({ guesses }) => {
  return (
    <div className="prev-guesses-container">
      <h2 className="prev-guesses-heading">Previous Guesses:</h2>
      {guesses.length === 0 ? (
        <p className="no-guesses-message"></p>
      ) : (
        <div className="guess-list">
          {guesses.map((guess, index) => (
            <div key={index} className="guess">
              {guess[0]} {guess[1] !== "" ? guess[1] : "-"}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
