import React from "react";
import "./index.css";

export const QuoteNumber = ({ number, isActive, isVisible, onClick }) => {
  const baseClassName = 'quoteNumberBox';
  const activeClassName = isActive ? `${baseClassName} active` : baseClassName;
  const visibleClassName = isVisible ? `${activeClassName} visible` : activeClassName;

  return (
    <div className={visibleClassName} onClick={() => onClick(number)}>
      <div className="quoteNumberText">{number}</div>
    </div>
  );
};