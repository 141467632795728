import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export const CongratsModal = ({ closeModal, prevGuesses, correctGuess }) => {
  const remainingSquares = 6 - prevGuesses.length;
  const blackSquares = Array.from({ length: remainingSquares }, () => '⬛').join('');
  const results = prevGuesses.map(([icon]) => `${icon}`).join('') + blackSquares;

  const copyToClipboard = () => {
    try {
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = ["Screenplayed", results, "https://www.screen-played.com/"].join('\n');
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand('copy');
      document.body.removeChild(tempTextArea);

      // Provide visual feedback to the user (e.g., show a success message)
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      // Handle the error (e.g., show an error message)
    }
  };

  return (
    <div className="modal-overlay">
      <div className="congrats-modal">
        {correctGuess ? (
          <>
            <h2>Congratulations!</h2>
            <p>You got it right!</p>
            <p>{results}</p>
          </>
        ) : (
          <>
            <h2>Sorry!</h2>
            <p>Try again tomorrow!</p>
            <p>{results}</p>
          </>
        )}
        <button onClick={copyToClipboard}>Copy Results</button>
        <button onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

CongratsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  prevGuesses: PropTypes.array.isRequired,
  correctGuess: PropTypes.bool.isRequired,
};
